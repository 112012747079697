import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/gQ2wMfKY3Xs">
    <SEO title="A Better House - Hebrews" />
  </Layout>
)

export default SermonPost
